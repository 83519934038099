import '../assets/styles/Header.sass';
import Maris from '../assets/images/logo.png';
import HeaderVideo from '../assets/images/header.mp4';
import PostVideo from '../assets/images/header.png';
import HeaderGif from '../assets/images/header.gif';

export default function Header(){

  const scrollTo = () => {
    const element = document.getElementById("presentation");
    element.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <header id="header" className="header">
      {/* <video className="video" muted={true} playsInline={true} autoPlay loop poster={PostVideo}  >
        <source src='/header.gif' type="video/gif" />
      </video> */}
      <img src={HeaderGif} className="video" />

      <img src={HeaderGif} className="video_mobile" />

      <img className="maris" src={Maris} alt="Maris" />

      <div className="content">
        <h1>O comercial que une a alma carioca do Leblon à curadoria artística de Lenny Niemeyer</h1>
        <button onClick={scrollTo}>Saiba mais</button>
      </div>
    </header>
  );
}