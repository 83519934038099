import logo from './logo.svg';
import './App.sass';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Presentation from './components/Presentation';
import Datasheet from './components/Datasheet';
import LocationIcons from './components/LocationIcons';
import LocationMap from './components/LocationMap';
import Project from './components/Project';
import Book from './components/Book';
import Cta from './components/Cta';
import Footer from './components/Footer';
import Credit from './components/Credit';
import Menu from './components/Menu';
import Modal from './components/Modal';
import Warning from './components/Warning';

function App() {
  return (
    <div className="App">
      <Menu />
      <Navbar />
      <Header />

      <Datasheet />
      <Presentation />      

      <Project />

      <Credit />

      <LocationIcons />
      <LocationMap />

      <Book />

      <Footer />


      <Cta />
      <Modal />
      <Warning />
    </div>
  );
}

export default App;
