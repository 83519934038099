import '../assets/styles/Book.sass';
import BookImage from '../assets/images/book.png';
import BookPattern from '../assets/images/book_pattern.png';
import InputMask from 'react-input-mask';
import { useState } from 'react';

export default function Book(){
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [checkbox, setCheckbox] = useState(false);

  function openModal() {
    document.querySelector('.modal').style.display = 'flex';
  }

  function checkData(e) {

    if (checkbox) {


      if (name != "" && email != "" && phone != "") {
        sendData(name, email, phone);
      } else {
        alert('Preencha todos os campos antes de enviar');
      }

    } else {
      alert('Preencha o checkbox antes de enviar');
    }

  }

  const openBook = () => {
    window.open('/book.pdf', '_blank')
  }

  function getMediaFromURL() {
    var url = window.location.href;
    var allParams = url.split('?');

    if (allParams.length < 2) {
      return 'Direct'
    }

    var params = allParams[1].split('&');

    var source, utm_source, utm_medium, utm_campaign;

    if (params.find(element => element.includes('gclid')) != undefined) {
      // gclid = params.find(element => element.includes('utm_campaign')).split('=')[1];
      return 'Google Ads';
    }

    // if(params.find(element => element.includes('posicionamento')) != undefined){
    //   source = params.find(element => element.includes('posicionamento')).split('=')[1];
    //   return source
    // }

    if (params.find(element => element.includes('utm_source')) != undefined) {
      source = params.find(element => element.includes('utm_source')).split('=')[1];
      return source;
    }


    return 'Landing page';
  }

  function sendData(name, email, phone) {
    var midia = getMediaFromURL();
    fetch('https://mozak.hypnobox.com.br/email.receber.php', {
      method: 'POST',
      body: JSON.stringify({
        nome: name,
        email: email,
        tel_celular: phone,
        id_produto: 34,
        midia: midia
      })
    })
      .then(response => response.json())
      .then(data => {
        alert('Dados enviados com sucesso!');
        setName("");
        setEmail("");
        setPhone("");
        setCheckbox(false);
        // open book in new tab
        window.open('/book.pdf', '_blank')
        window.location.reload();
      })
  }

  const activeLabel = (e) => {
    e.target.previousElementSibling.classList.add('active');
  }

  const inactiveLabel = (e) => {
    if(e.target.value === ''){
      e.target.previousElementSibling.classList.remove('active');
    }
  }

  return (
    <section id="book" className="book">

      <p className="section-title">Book</p>

      <img src={BookPattern} alt="Book Pattern" className="book_pattern" />
      
      <div className="book_image">
        <img src={BookImage} alt="Book" />
      </div>

      <div className="book_form">
        <h2>Acesse agora o book e receba informações exclusivas dos nossos especialistas</h2>

        <div className="input-wrapper">
          <label>Nome completo</label>
          <input type="text" onFocus={activeLabel} onBlur={inactiveLabel} onChange={(e) => setName(e.target.value)} />
        </div>
        
        <div className="input-wrapper">
          <label>Email</label>
          <input type="text" onFocus={activeLabel} onBlur={inactiveLabel} onChange={(e) => setEmail(e.target.value)} />
        </div>
        
        <div className="input-wrapper">
          <label>Telefone</label>
          <InputMask type='text' mask="(99) 99999-9999" onChange={(e) => setPhone(e.target.value)} onFocus={activeLabel} onBlur={inactiveLabel} />
        </div>


        <div className="input-content-checkbox">
          <input type="checkbox" id="checkbox_book" onChange={(e) => setCheckbox(e.target.checked)} />
          <label htmlFor="checkbox_book">Li e aceito os <span onClick={openModal}>termos de uso e políticas de privacidade</span></label>
        </div>

        <div className="button-content">
          <button onClick={checkData}>ENVIAR</button>
        </div>

      </div>

    </section>
  );
}