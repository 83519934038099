import ProjectText from '../assets/images/project_text.png';
import ProjectImage from '../assets/images/project_image.png';
import ProjectSignature from '../assets/images/project_signature.png';
import Pattern from '../assets/images/pattern.png';
import '../assets/styles/Project.sass';

export default function Project() {
  return (
    <section id="project" className="project">
      
      <p className="section-title"> O projeto </p>
      <img className="pattern" src={Pattern} alt="pattern" />

      <div className="project_text">
        <img className="text" src={ProjectText} alt="project text" />
        <img className="signature" src={ProjectSignature} alt="project signature" />
      </div>

      <div className="project_image">
        <img src={ProjectImage} alt="project image" />
      </div>

    </section>
  );
}