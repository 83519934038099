import '../assets/styles/Datasheet.sass';
import Info from '../assets/images/datasheet_info.png';
import Left from '../assets/images/left.png';
import Right from '../assets/images/right.png';
import Carrousel1 from '../assets/images/carrousel1.png'
import Carrousel2 from '../assets/images/carrousel2.png'
import Carrousel3 from '../assets/images/carrousel3.png'
import Carrousel4 from '../assets/images/carrousel4.png'
import Carrousel5 from '../assets/images/carrousel5.png'
import Carrousel1Mobile from '../assets/images/carrousel1_mobile.png'
import Carrousel2Mobile from '../assets/images/carrousel2_mobile.png'
import Carrousel3Mobile from '../assets/images/carrousel3_mobile.png'
import Carrousel4Mobile from '../assets/images/carrousel4_mobile.png'
import Carrousel5Mobile from '../assets/images/carrousel5_mobile.png'
import Pattern from '../assets/images/book_pattern.png';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

export default function Datasheet(){
  const [current, setCurrent] = useState(0);

  const carrousel = [Carrousel1, Carrousel2, Carrousel3, Carrousel4, Carrousel5];
  const carrouselMobile = [Carrousel1Mobile, Carrousel2Mobile, Carrousel3Mobile, Carrousel4Mobile, Carrousel5Mobile];

  const next = () => {
    if(current < carrousel.length - 1){
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  }

  const prev = () => {
    if(current > 0){
      setCurrent(current - 1);
    } else {
      setCurrent(carrousel.length - 1);
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev()
  });


  return (
    <section id="datasheet" className="datasheet">
      
      <p className="section-title">
        Ficha Técnica
      </p>

      <img className="pattern" src={Pattern} alt="pattern" />

      <div className="datasheet_info">
        <img src={Info} alt="datasheet info" />
      </div>
      
      <div className="datasheet_carrousel_desktop">
        <img src={Left} alt="left" className="left" onClick={prev}/>
        <img src={carrousel[current]} alt="carrousel 1" className="carrousel" />
        <img src={Right} alt="" className="right" onClick={next}/>
      </div>
      
      <div className="datasheet_carrousel_mobile">
        <img src={Left} alt="left" className="left" onClick={prev}/>
        <img src={carrouselMobile[current]} alt="carrousel 1" className="carrousel" {...handlers}/>
        <img src={Right} alt="" className="right" onClick={next}/>
      </div>

    </section>
  );
}