import CreditImage from '../assets/images/project_credit.png';
import CreditImageMobile from '../assets/images/project_credit_mobile.png';
import '../assets/styles/Credit.sass';

export default function Credit(){
  return (
    <section className="credit">
      <img className="desktop" src={CreditImage} alt="Créditos" />
      <img className="mobile" src={CreditImageMobile} alt="Créditos" />
    </section>
  );
}