import '../assets/styles/Navbar.sass';
import Mozak from '../assets/images/mozak.png';
import Bsd from '../assets/images/bsd.png';
import Menu from '../assets/images/menu.png';

export default function Navbar(){
  const openMenu = () => {
    const menu = document.querySelector('.menu');
    menu.style.left = '0px';
  }

  return (
    <nav className="navbar">
      <img className="menu_icon" src={Menu} alt="Menu" onClick={openMenu} />
      <img className="bsd" src={Bsd} alt="BSD" />

    </nav>
  );
}