import Icon1 from '../assets/images/location_icon1.png'
import Icon2 from '../assets/images/location_icon2.png'
import Icon3 from '../assets/images/location_icon3.png'
import Icon1Mobile from '../assets/images/location_icon1_mobile.png'
import Icon2Mobile from '../assets/images/location_icon2_mobile.png'
import Icon3Mobile from '../assets/images/location_icon3_mobile.png'
import '../assets/styles/LocationIcons.sass'

export default function LocationIcons(){
  return (
    <section id="location" className="location_icons">
      <p className="section-title">
        Localização
      </p>

      <div className="icons_desktop">
        <img src={Icon1} alt="icon1" />
        <img src={Icon2} alt="icon2" />
        <img src={Icon3} alt="icon3" />
      </div>
      
      <div className="icons_mobile">
        <img src={Icon1Mobile} alt="icon1" />
        <img src={Icon2Mobile} alt="icon2" />
        <img src={Icon3Mobile} alt="icon3" />
      </div>
    </section>
  );
}