import MenuIcon from '../assets/images/menu_icon.png';
import '../assets/styles/Menu.sass';

export default function Menu() {
  const scrollTo = (e) => {
    //get href property
    const href = e.target.getAttribute('href');
    const element = document.getElementById(href);
    element.scrollIntoView({ behavior: "smooth" });
    closeMenu();
  }

  const closeMenu = () => {
    const menu = document.querySelector('.menu');
    menu.style.left = '-100%';
  }

  return (
    <div className="menu">
      <img src={MenuIcon} alt="menu" onClick={closeMenu}/>
      <ul>
        <li href="header" onClick={scrollTo}>Início</li>
        <li href="presentation" onClick={scrollTo}>Apresentação</li>
        <li href="datasheet" onClick={scrollTo}>Ficha Técnica</li>
        <li href="location" onClick={scrollTo}>Localização</li>
        <li href="project" onClick={scrollTo}>O Projeto</li>
        <li href="book" onClick={scrollTo}>Book</li>
      </ul>
    </div>
  );
}