import '../assets/styles/Warning.sass';

export default function Warning() {

  function openModal() {
    document.querySelector('.modal').style.display = 'flex';
  }

  const handleAccept = () => {
    window.localStorage.setItem('cookieAccepted', true);

    const warning = document.querySelector('.warning');
    warning.style.display = 'none';
  }

  if (window.localStorage.getItem('cookieAccepted')) {
    return null;
  }

  return (
    <div className="warning">
      <p>Para melhorar a sua experiência de navegação, utilizamos de cookies, entre outras tecnologias. De acordo com nossa <span onClick={openModal}>Política de Privacidade</span>, ao continuar navegando, você aceita estas condições.</p>
      <button onClick={handleAccept}>Concordar e continuar</button>
    </div>
  );
}